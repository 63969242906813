.firebaseui-list-item {
	display: block;
	width: 50vw;
}
.firebaseui-list-item button {
	width: 100%;
}
.firebaseui-idp-list {
	align-items: center;
	display: flex;
	flex-direction: column;
	padding-left: 0px;
}
span.firebaseui-idp-text-long {
	display: none;
}
span.firebaseui-idp-icon-wrapper {
	width: 20%;
	height: 100%;
}
