#firebaseui-auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.firebaseui-card-footer {
    width: 50vw;
}
.back-button-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.back-to-home-button{
    align-self: center;
}