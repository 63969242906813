fieldset.merchant {
    display: flex;
    flex-direction: column;
}

p.small-pill {
    border: 1px solid grey;
    padding: 8px;
    border-radius: 24px;
    width: fit-content;
    margin-inline: 2px;
    margin-block: 6px;
}

p.small-pill-red {
    border: 1px solid rgb(232, 62, 62);
    color: rgb(232, 62, 62);
    padding: 8px;
    border-radius: 24px;
    width: fit-content;
    margin-inline: 2px;
    margin-block: 6px;
}

.accountManager-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-block: 6px;
}

.singleAccountManager-row {
    display: flex;
    flex-direction: row;
}

.singleAccountManager-row input {
    margin: 0;
    width: 90%;
}

.singleAccountManager-row button {
    border-radius: 25%;
}