.viewperks .navTo-create-perk-button {
    margin: 0;
    padding: 1rem;
    border-radius: 25px;
    align-self: center;
}

.perks-box .hideable-div {
    background-color: cadetblue;
    margin: 10px;
    padding: 4px;
    margin-top: 0px;
    border-radius: 5px;
}

.perks-box {
    display: flex;
    flex-direction: column;
}

button.viewperks-single-perk-button {
    text-align: left;
    /* width: fit-content; */
    margin-block: 6px;
    padding-block: 2%;
    padding-inline: 8%;
    /* border-radius: 25%; */
    border-top: 2px solid darkslategray;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid darkslategray;
    background-color: rgb(147, 168, 189);
}

button.viewperks-single-perk-button p {
    margin-block: 0px;
    color: black
}

.merchant-group {
    margin-bottom: 16px;
    border-bottom: 2px dashed grey;
}

.merchant-group:last-of-type {
    border-bottom: none;
}