@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&display=swap');

@font-face {
    font-family: 'InterTight';
    src:
        url('/public/fonts/InterTight-VariableFont_wght.ttf') format('woff2 supports variations'),
        url('/public/fonts/InterTight-VariableFont_wght.ttf') format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
}

:root {
    --yp-primary: rgba(5, 7, 9, 1);
    --yp-primary-accent: rgba(255, 169, 69, 1);
    --yp-primary-accent-light: rgba(255, 105, 237, .2);  
    --yp-primary-form-bg: rgba(5, 7, 9, 0.03);
    --yp-dark: rgba(0, 0, 1, 1);
    --yp-semidark: rgba(5, 7, 9, .66);
    --yp-charcoal: rgba(5, 7, 9, .59);
    --yp-gray: rgba(5, 7, 9, .39);
    --yp-highlight: rgba(5, 7, 9, .13);
    --yp-link: rgba(196, 123, 38, 1);
    --yp-smoke: rgba(5, 7, 9, .02);
    --yp-white: rgba(255, 255, 255, 1);
    --yp-width: 370px;
}

* {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

::selection {
    background: var(--yp-highlight);
}

::placeholder {
    color: var(--yp-charcoal);
}

html {
    height: 100%;
    margin: 0;
    overflow-y: hidden;
    padding: 0;
    width: 100%;
}

b, .b {
    font-weight: 500;
}

body {
    background-color: var(--yp-white);
    color: var(--yp-dark);
    font-family: 'InterTight', -apple-system, sans-serif;
    font-size: 62.5%;
    font-weight: 400;
    height: 100%;
    width: 100%;
}

h1 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 0.85;
    margin: 1.25rem auto;
}

.header > div > h1 {
    color: var(--yp-primary-accent);
    font-family: 'Bricolage Grotesque', -apple-system, serif;
    font-size: 3.33rem;
    margin-top: 0.75rem;
}


.header > div > h1 > em {
    display: inline-flex;
    font-style: normal;
    transform: rotate(7deg);
}

h2 {
    font-size: 1.69rem;
    font-weight: 500;
    line-height: 1;
    margin: 0 auto 1.33rem auto;
    padding: 0;
}

h3 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0.5rem auto;
}

h4 {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.4;
}

h5 {
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0 auto 0.33rem auto;
    width: 85%;
}

p {
    color: var(--yp-charcoal);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.44rem;
}

a {
    color: var(--yp-link);
    text-decoration: underline;
}

b {
    font-weight: 500;
}

main div {
    height: auto;
    margin: 0 auto 2rem auto;
    max-width: 25rem;
    width: auto;
}

.dl {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 50px;
    margin: .5rem;
    padding: 0;
    width: 11rem;
}

#android {
    background-image: url("/public/badge-android.svg");
}

#ios {
    background-image: url("/public/badge-ios.svg");
}

#flex {
    align-content: center;
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 0 auto;
    width: 100%;
}

.flex-item {
    height: auto;
    margin: 0 1rem;
    min-height: 32vh;
    padding: 0;
}

.flex-item img {
    height: 45%;
    margin: 0 18%;
    width: 66%;
}

#flex p {
    font-size: 1rem;
    line-height: 1.3;
    margin: 0 auto;
    text-align: center;
    width: 99%;
}

.flex1,
.flex2,
.flex3 {
    flex: 1 1 1em;
}

.flex3 {
    margin-top: 2rem;
}

.flex3 img {
    margin-top: -7px;
}

.flex3 h5 {
    margin-top: 0.4rem;
}

#flex>div.flex-item.flex2>h5 {
    margin-top: -2rem;
}

.center,
.cen {
    text-align: center;
}

.effective {
    font-stretch: extra-condensed;
    font-size: .93rem;
}

.button,
input[type=submit] {
    background-color: var(--yp-primary);
    border-radius: 0.5rem;
    border: 0;
    box-shadow: none;
    color:var(--yp-primary-accent);
    cursor: pointer;
    display: inline-block;
    font-size: .91rem;
    font-weight: 600;
    min-width: 5rem;
    padding: 0.9rem;
    position: relative;
    text-decoration: none;
    transition: box-shadow 0.67s;
    width: auto;
}


table {
    border-collapse: collapse;
    border: 0;
    margin-left: auto;
    margin-right: auto;
    width: 99%;
}

table tbody th,
table tbody td {
    border: none;
    border-bottom: .22rem solid var(--yp-gray);
    font-size: 1rem;
    padding: 1rem 1.5rem;
}

table tbody tr {
    background-color: var(--yp-smoke);
}

table thead th {
    border: none;
    border-bottom: .3rem solid var(--yp-gray);
    color: var(--yp-dark);
    font-size: 1.1rem;
    padding: 1rem 2rem;
}

td:nth-child(1) {
    font-family: monospace;
}

td:nth-child(3) {
    font-style: oblique;
}

th.taps {
    width: 5%
}

th.date {
    width: 12%
}

th.id {
    width: 20%
}

th.title {
    width: 68%
}

fieldset {
    border: 0;
}

input,
select,
textarea,
button,
a {
    outline-color: var(--yp-gray);
}

legend {
    display: block;
    font-size: 1.5rem;
    line-height: 1.7;
}

label {
    color: var(--yp-charcoal);
    display: inline-block;
    font-size: .95rem;
    line-height: 1.4;
    font-weight: 500;
    vertical-align: super;
}

.sm {
    font-size: 0.85rem;
}

.grey {
    background-color: var(--yp-dark);
}

form {
    margin: 0 auto;
}

textarea {
    height: 5rem;
}

#merchants {
    margin: 1.5rem auto;
}

#merchants h4 {
    margin-bottom: 0;
}

#merchplan-change {
    align-items: baseline;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: flex-start;
    width: 50%;
}

#merchplan-change select {
    margin: auto;
    /* Magic! */
    max-width: 575px;
}

#merchplan-change input[type=submit] {
    margin: auto;
    /* Magic! */
    padding: .33rem;
}

input[type=number],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=email],
input[type=tel],
input[type=text],
input[type=url],
textarea,
select {
    -moz-appearance: none;
    -moz-user-select: none;
    -ms-user-select: none;
    appearance: none;
    background-color: var(--yp-primary-form-bg);
    border-bottom: 0.1rem solid var(--yp-highlight);
    border-radius: .05rem;
    border-top: none;
    border: 0.1rem solid transparent;
    box-shadow: inset 0 1px 2px var(--yp-gray), 0 -1px 1px transparent, 0 1px 0 transparent;
    color: var(--yp-gray);
    cursor: pointer;
    display: block;
    font-family: sans-serif;
    line-height: 1.5rem;
    margin: 0 0 1rem 0;
    padding: .33rem;
    position: relative;
    transition: .2s cubic-bezier(.3, 0, .5, 1);
    user-select: none;
}

select {
    float: left;
    appearance: auto;
}

input[type=checkbox] {
    display: inline-flex;
    float: left;
    height: 1rem;
    margin-right: .5rem;
    transform: scale(1.22);
    width: 1rem;
}

input[type=range] {
    display: block;
    float: left;
    height: 1rem;
}

datalist {
    display: inline-flex;
    clear: both;
    flex-direction: column;
    float: left;
    justify-content: space-between;
    text-orientation: upright;
    writing-mode: vertical-lr;
}

datalist option:nth-child(10) {
    writing-mode: horizontal-tb;
}

fieldset {
    margin: auto;
    padding: 0;
}

#merchpayment>p:nth-child(7) {
    line-height: .5;
    font-size: .69rem;
}

#remaining {
    background-color: var(--yp-primary-accent);
    border: 1px solid var(--yp-semidark);
    padding: 0 .33rem;
}

/* Create perk css */
#map {
    border: .1rem solid var(--yp-charcoal);
    display: block;
    height: 275px;
    min-height: 45vh;
    margin-bottom: 1rem;
    margin-left: 0;
}

mark {
    background-color: var(--yp-primary-accent);
}

mark::before {
    content: '$';
}

input[type="radio"] {
    accent-color: #cefffe;
    margin: 0 .33rem;
    font: inherit;
    color: var(--yp-semidark);
    width: 1.1rem;
    height: 1.1rem;
    border: 0.1rem solid var(--yp-semidark);
    border-radius: 50%;
    place-content: center;
}

.button.accent {
    color: var(--yp-primary-accent);
}

.button:hover {
    box-shadow: 1px 1px 5px var(--yp-primary-accent);
}

aside {
    background-image: url('/public/notification-frame.png');
    background-repeat: no-repeat;
    background-position: -10px 0;
    display: block;
    height: 275px;
    position: relative;
    width: 400px;
}

aside h6 {
    height: 20px;
    left: 50px;
    position: relative;
    top: 34px;
}

aside p {
    display: block;
    font-size: .93rem;
    height: 157px;
    line-height: 1.3rem;
    margin: 3rem 0 0 2rem;
    width: 290px;
}

footer {
    margin: 0 auto;
    padding-bottom: 1rem;
    width: var(--yp-width);
}

footer p {
    font-size: .8rem;
    font-stretch: semi-condensed;
    text-align: center;
}

footer p a {
    margin-right: 1.23rem;
}

button {
    cursor: pointer;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.app-container {
    position: absolute;
    width: 100%;
    height: 100%;
}


div.page-container {
    overflow-y: scroll;
    height: 90%;
    padding: 1% 3%;
}

@media (width <= 431px) {
    #flex { flex-direction: column; }
    header > div > h1 { font-size: 2.5rem;} 
}

h1.page-title {
    font-size: xx-large;
    text-align: center;
  }