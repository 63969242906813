.header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    justify-content: center;
    z-index: 100;
    margin-bottom: 2px;
    background-color: #333;
    background-image:
    linear-gradient(45deg, black 25%, transparent 25%, 
      transparent 75%, black 75%, black),
    linear-gradient(45deg, black 25%, transparent 25%, 
      transparent 75%, black 75%, black);
  background-size: 6px 6px;
  background-position: 0 0, 3px 3px;

}

button.login {
    position: absolute;
    right: 1%;
    padding: 6px 8px;
}

button.user-logo {
    border-radius: 36%;
    display: block;
    position: fixed;
    height: 10%;
    right: 1px;
}

img.user-profile-picture {

    border-radius: 36%;
    height: 100%;
    width: 100%;
}

.header.h1 {
    font-size: larger;
    font-family: "Bricolage Grotesque", serif;
    display: inline-block;
}

.header-display-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

img.header-yay-img {
    display: inline-block;
    height: 50px;
}

/* Dropdown menu */

div.hidden {
    display: none;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 1px;
    top: 10%;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0px;
    z-index: 1;
    background-color: seashell;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.dropdown-content button {
    padding: 4px 6px;
    border: solid tan 1px;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}