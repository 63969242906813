.merchant-list-item {
    border: 2px solid gray;
    border-radius: 15%;
    padding: 6%;
    overflow-x: hidden;
    margin-block-end: 10px;
}

.merchant-list-item-editsave-button {
    margin-inline: 49%;
}

.merchant-name {
    padding: 15px;
    margin-bottom: 0px;
}

.down-arrow {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
}

.up-arrow {
    width: 20px;
    height: 20px;
}

.hidden-div {
    margin-inline: 20px;
    background-color: rgb(206, 237, 239);
    padding: 4% 5%;
    border-radius: 15px;
}